var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c("h3", [_vm._v("自动审核")]),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-table",
            {
              attrs: { stripe: "", data: _vm.tableData, "show-header": false },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "55", align: "center" },
              }),
              _vm._l(_vm.colemd, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      list.label == "开关"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": "1",
                                    "inactive-value": "0",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handlerSwitch(row)
                                    },
                                  },
                                  model: {
                                    value: row.value,
                                    callback: function ($$v) {
                                      _vm.$set(row, "value", $$v)
                                    },
                                    expression: "row.value",
                                  },
                                }),
                              ]
                            },
                          }
                        : list.label == "功能审核"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm._v(" " + _vm._s(row.text) + " "),
                                row.key == "auto_order_back"
                                  ? _c(
                                      "div",
                                      [
                                        _c("span", [_vm._v("退库仓库:")]),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              width: "120px",
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              clearable: "",
                                              size: "mini",
                                              placeholder: "请选择退库仓库",
                                            },
                                            on: { change: _vm.depotChange },
                                            model: {
                                              value: _vm.depot,
                                              callback: function ($$v) {
                                                _vm.depot = $$v
                                              },
                                              expression: "depot",
                                            },
                                          },
                                          _vm._l(
                                            _vm.depotSelect,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.id,
                                                  label: i.depot_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                row.key == "auto_order_car_back"
                                  ? _c(
                                      "div",
                                      [
                                        _c("span", [_vm._v("退库仓库:")]),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              width: "120px",
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              clearable: "",
                                              size: "mini",
                                              placeholder: "请选择退库仓库",
                                            },
                                            on: { change: _vm.depotChange2 },
                                            model: {
                                              value: _vm.depot2,
                                              callback: function ($$v) {
                                                _vm.depot2 = $$v
                                              },
                                              expression: "depot2",
                                            },
                                          },
                                          _vm._l(
                                            _vm.depotSelect,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.id,
                                                  label: i.depot_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("预订单审核控制")]),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-table",
            {
              attrs: { stripe: "", data: _vm.tableData3, "show-header": false },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "55", align: "center" },
              }),
              _vm._l(_vm.colemd, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      list.label == "开关"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": "1",
                                    "inactive-value": "0",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handlerSwitch(row)
                                    },
                                  },
                                  model: {
                                    value: row.value,
                                    callback: function ($$v) {
                                      _vm.$set(row, "value", $$v)
                                    },
                                    expression: "row.value",
                                  },
                                }),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("业务资源共享")]),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-table",
            {
              attrs: { stripe: "", data: _vm.tableData1, "show-header": false },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "55", align: "center" },
              }),
              _vm._l(_vm.colemd, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      list.label == "开关"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": "1",
                                    "inactive-value": "0",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handlerSwitch(row)
                                    },
                                  },
                                  model: {
                                    value: row.value,
                                    callback: function ($$v) {
                                      _vm.$set(row, "value", $$v)
                                    },
                                    expression: "row.value",
                                  },
                                }),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("业务规范")]),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-table",
            {
              attrs: {
                stripe: "",
                data: _vm.tableData2,
                "show-header": false,
                "span-method": _vm.arraySpanMethod,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "55", align: "center" },
              }),
              _vm._l(_vm.colemd, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                  },
                  scopedSlots: _vm._u(
                    [
                      list.label == "开关"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                index == 8
                                  ? _c("el-switch", {
                                      attrs: {
                                        "active-value": "1",
                                        "inactive-value": "0",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handlerSwitch(row)
                                        },
                                      },
                                      model: {
                                        value: row.value,
                                        callback: function ($$v) {
                                          _vm.$set(row, "value", $$v)
                                        },
                                        expression: "row.value",
                                      },
                                    })
                                  : _c("el-switch", {
                                      attrs: {
                                        "active-value": "1",
                                        "inactive-value": "0",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handlerSwitch(row)
                                        },
                                      },
                                      model: {
                                        value: row.value,
                                        callback: function ($$v) {
                                          _vm.$set(row, "value", $$v)
                                        },
                                        expression: "row.value",
                                      },
                                    }),
                              ]
                            },
                          }
                        : list.label == "功能审核"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.key == "flag_date_type"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" 生产日期: ")]
                                    )
                                  : row.key == "flag_visit_type"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" 店铺拜访时间更新方式: ")]
                                    )
                                  : row.key == "flag_depot_back"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _vm._v(" 限定车存退库仓库 "),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              width: "120px",
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              multiple: "",
                                              clearable: "",
                                              size: "mini",
                                              placeholder:
                                                "请选择限定车存退库仓库",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.depotChangeO(
                                                  $event,
                                                  "depot_back"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.depot3,
                                              callback: function ($$v) {
                                                _vm.depot3 = $$v
                                              },
                                              expression: "depot3",
                                            },
                                          },
                                          _vm._l(
                                            _vm.depotSelect,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.id,
                                                  label: i.depot_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              width: "120px",
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              clearable: "",
                                              size: "mini",
                                              placeholder: "请选择默认退库仓库",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.depotChangeO(
                                                  $event,
                                                  "depot_back_default"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.depot4,
                                              callback: function ($$v) {
                                                _vm.depot4 = $$v
                                              },
                                              expression: "depot4",
                                            },
                                          },
                                          _vm._l(
                                            _vm.depotSelectDefault,
                                            function (i, idx) {
                                              return _c("el-option", {
                                                key: idx,
                                                attrs: {
                                                  value: i.id,
                                                  label: i.depot_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : row.key == "flag_carport_apply"
                                  ? _c(
                                      "div",
                                      [
                                        _vm._v(" " + _vm._s(row.text) + " "),
                                        _c("span", [
                                          _vm._v(
                                            "限定人" + _vm._s(row.option_value)
                                          ),
                                        ]),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px",
                                            },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleSetDefa(row)
                                              },
                                            },
                                          },
                                          [_vm._v(" 设置 ")]
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _vm._v(" 限定时间: "),
                                            _c("el-time-picker", {
                                              staticStyle: { width: "330px" },
                                              attrs: {
                                                "is-range": "",
                                                format: "HH:mm",
                                                "value-format": "HH:mm",
                                                "range-separator": "至",
                                                "start-placeholder": "开始时间",
                                                "end-placeholder": "结束时间",
                                                placeholder: "选择时间范围",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var a = $event
                                                  _vm.depotChangeO(
                                                    a[0],
                                                    "carport_apply_start"
                                                  )
                                                  _vm.depotChangeO(
                                                    a[1],
                                                    "carport_apply_end"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.time16,
                                                callback: function ($$v) {
                                                  _vm.time16 = $$v
                                                },
                                                expression: "time16",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : row.key == "flag_order_follow"
                                  ? _c(
                                      "div",
                                      [
                                        _vm._v(" " + _vm._s(row.text) + " "),
                                        _c("span", [
                                          _vm._v(
                                            "限定人" + _vm._s(row.option_value)
                                          ),
                                        ]),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px",
                                            },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleSetDefa(row)
                                              },
                                            },
                                          },
                                          [_vm._v(" 设置 ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : row.key == "flag_base_apply"
                                  ? _c(
                                      "div",
                                      [
                                        _vm._v(" " + _vm._s(row.text) + " "),
                                        _c("el-time-picker", {
                                          staticStyle: { width: "330px" },
                                          attrs: {
                                            "is-range": "",
                                            format: "HH:mm",
                                            "value-format": "HH:mm",
                                            "range-separator": "至",
                                            "start-placeholder": "开始时间",
                                            "end-placeholder": "结束时间",
                                            placeholder: "选择时间范围",
                                          },
                                          on: {
                                            change: function ($event) {
                                              var a = $event
                                              _vm.depotChangeO(
                                                a[0],
                                                row.option_key1
                                              )
                                              _vm.depotChangeO(
                                                a[1],
                                                row.option_key2
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.time18,
                                            callback: function ($$v) {
                                              _vm.time18 = $$v
                                            },
                                            expression: "time18",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : row.key == "flag_order_apply"
                                  ? _c(
                                      "div",
                                      [
                                        _vm._v(" " + _vm._s(row.text) + " "),
                                        _c("el-time-picker", {
                                          staticStyle: { width: "330px" },
                                          attrs: {
                                            "is-range": "",
                                            format: "HH:mm",
                                            "value-format": "HH:mm",
                                            "range-separator": "至",
                                            "start-placeholder": "开始时间",
                                            "end-placeholder": "结束时间",
                                            placeholder: "选择时间范围",
                                          },
                                          on: {
                                            change: function ($event) {
                                              var a = $event
                                              _vm.depotChangeO(
                                                a[0],
                                                row.option_key1
                                              )
                                              _vm.depotChangeO(
                                                a[1],
                                                row.option_key2
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.time19,
                                            callback: function ($$v) {
                                              _vm.time19 = $$v
                                            },
                                            expression: "time19",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [_vm._v(_vm._s(row.text))]),
                              ]
                            },
                          }
                        : list.label == "审核说明"
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.key == "flag_date_type"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "2" },
                                            on: { change: _vm.changeTime },
                                            model: {
                                              value: row.value,
                                              callback: function ($$v) {
                                                _vm.$set(row, "value", $$v)
                                              },
                                              expression: "row.value",
                                            },
                                          },
                                          [_vm._v(" 系统智能推算成产日期 ")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "1" },
                                            on: { change: _vm.changeTime },
                                            model: {
                                              value: row.value,
                                              callback: function ($$v) {
                                                _vm.$set(row, "value", $$v)
                                              },
                                              expression: "row.value",
                                            },
                                          },
                                          [_vm._v(" 按最近三次采购先进先出 ")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "3" },
                                            on: { change: _vm.changeTime },
                                            model: {
                                              value: row.value,
                                              callback: function ($$v) {
                                                _vm.$set(row, "value", $$v)
                                              },
                                              expression: "row.value",
                                            },
                                          },
                                          [_vm._v(" 按最近三次采购后进先出 ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : row.key == "flag_visit_type"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "1" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeTime2(
                                                  $event,
                                                  "flag_visit_type"
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.value,
                                              callback: function ($$v) {
                                                _vm.$set(row, "value", $$v)
                                              },
                                              expression: "row.value",
                                            },
                                          },
                                          [_vm._v(" 按业务员各自的拜访时间 ")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { label: "2" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeTime2(
                                                  $event,
                                                  "flag_visit_type"
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.value,
                                              callback: function ($$v) {
                                                _vm.$set(row, "value", $$v)
                                              },
                                              expression: "row.value",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " 按店铺最新一次拜访时间（不区分拜访业代） "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", [_vm._v(_vm._s(row.desc))]),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("其他设置")]),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _vm._l(_vm.other, function (item, idx) {
            return _c("div", { key: idx }, [
              item.key == "pur_price_door"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("span", [_vm._v("商品成本价核算方式：")]),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          on: {
                            change: function ($event) {
                              return _vm.changeTime2($event, "pur_price_door")
                            },
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        },
                        [_vm._v(" 加权平均成本价（按采购入库单动态计算） ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          on: {
                            change: function ($event) {
                              return _vm.changeTime2($event, "pur_price_door")
                            },
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        },
                        [_vm._v(" 固定成本价（商品资料中设定的成本价） ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "135px",
                            "margin-top": "15px",
                          },
                        },
                        [
                          _vm._v(
                            " 切换核算方式不影响之前已生成的销售单中的商品成本 "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "pur_price_user"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "flex-start",
                        "margin-bottom": "20px",
                      },
                    },
                    [
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(item.desc) + ":")]),
                        _c("span", { staticStyle: { "margin-left": "15px" } }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleSetA(item)
                            },
                          },
                        },
                        [_vm._v(" 设置 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "receive_only"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": item.text,
                          "active-value": "1",
                          "inactive-value": "0",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handlerSwitch1(item)
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(item.desc)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "distribute_only"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": item.text,
                          "active-value": "1",
                          "inactive-value": "0",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handlerSwitch1(item)
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(item.desc)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "staff_order_print"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": item.text,
                          "active-value": "1",
                          "inactive-value": "0",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handlerSwitch1(item)
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(item.desc)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "staff_base_print"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": item.text,
                          "active-value": "1",
                          "inactive-value": "0",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handlerSwitch1(item)
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(item.desc)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "pur_check_user"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "flex-start",
                        "margin-bottom": "20px",
                      },
                    },
                    [
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(item.desc) + ":")]),
                        _c("span", { staticStyle: { "margin-left": "15px" } }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleSetA(item)
                            },
                          },
                        },
                        [_vm._v(" 设置 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "clear_user"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "flex-start",
                        "margin-bottom": "20px",
                      },
                    },
                    [
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(item.desc) + ":")]),
                        _c("span", { staticStyle: { "margin-left": "15px" } }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleSetA(item)
                            },
                          },
                        },
                        [_vm._v(" 设置 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "print_auto_user"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "flex-start",
                        "margin-bottom": "20px",
                      },
                    },
                    [
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(item.desc) + ":")]),
                        _c("span", { staticStyle: { "margin-left": "15px" } }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleSetPringStaff(item)
                            },
                          },
                        },
                        [_vm._v(" 设置 ")]
                      ),
                      _c("span", [
                        _vm._v("（为避免重复打印，仅可添加一名员工账号）"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "allot_pay_door"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": item.text,
                          "active-value": "1",
                          "inactive-value": "0",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handlerSwitch1(item)
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(item.desc)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "allot_pay_user"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "flex-start",
                        "margin-bottom": "20px",
                      },
                    },
                    [
                      _c("div", [
                        _c("span", [_vm._v(_vm._s(item.desc) + ":")]),
                        _c("span", { staticStyle: { "margin-left": "15px" } }, [
                          _vm._v(_vm._s(item.text)),
                        ]),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleSetA(item)
                            },
                          },
                        },
                        [_vm._v(" 设置 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "pre_process_door"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": item.text,
                          "active-value": "1",
                          "inactive-value": "0",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handlerSwitch1(item)
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                      _c("div", { staticStyle: { "margin-top": "10px" } }, [
                        _vm._v(_vm._s(item.desc)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "align-items": "center",
              },
            },
            [
              _c("span", [_vm._v("拍照水印显示：")]),
              _vm._l(_vm.takep, function (list, index) {
                return _c("el-switch", {
                  key: index,
                  attrs: {
                    "active-text": list.text,
                    "active-value": "1",
                    "inactive-value": "0",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handlerSwitch1(list)
                    },
                  },
                  model: {
                    value: list.value,
                    callback: function ($$v) {
                      _vm.$set(list, "value", $$v)
                    },
                    expression: "list.value",
                  },
                })
              }),
            ],
            2
          ),
          _c("br"),
          _c("br"),
          _c("span", [_vm._v("允许看成本价员工：")]),
          _c("span", { staticStyle: { margin: "0px 15px" } }, [
            _vm._v(_vm._s(_vm.user_data)),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSet } },
            [_vm._v("设置")]
          ),
          _c("br"),
          _c("br"),
          _c("span", [_vm._v("距离门店超过")]),
          _c("el-input", {
            staticStyle: { width: "120px" },
            attrs: {
              placeholder: "请输入范围半径",
              onkeyup: "value=value.replace(/[^\\d.]/g,'') ",
            },
            on: { blur: _vm.handlerblur },
            model: {
              value: _vm.juli,
              callback: function ($$v) {
                _vm.juli = $$v
              },
              expression: "juli",
            },
          }),
          _c("span", [_vm._v("千米，做拜访操作，判断为位置异常 默认为0.5KM")]),
        ],
        2
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c("span", { staticStyle: { "margin-right": "10px" } }, [
            _vm._v("奖品成本计入毛利:"),
          ]),
          _vm._l(_vm.chenlielist, function (list, index) {
            return _c("el-switch", {
              key: index,
              staticStyle: { "margin-right": "10px" },
              attrs: {
                "active-text": list.text,
                "active-value": "1",
                "inactive-value": "0",
              },
              on: {
                change: function ($event) {
                  return _vm.handlerSwitch1(list)
                },
              },
              model: {
                value: list.value,
                callback: function ($$v) {
                  _vm.$set(list, "value", $$v)
                },
                expression: "list.value",
              },
            })
          }),
          _c("div", { staticStyle: { "margin-top": "10px" } }, [
            _vm._v(" 默认开启，关闭后，该销售类型的商品毛利为0 "),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "15px",
                display: "flex",
                "align-items": "center",
              },
            },
            [
              _c("span", { staticStyle: { "margin-right": "10px" } }, [
                _vm._v("手机端打印:"),
              ]),
              _vm._l(_vm.other, function (list, index) {
                return _c("div", { key: index }, [
                  list.key == "single_money_type"
                    ? _c(
                        "div",
                        [
                          _c("el-switch", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              "active-text": list.text,
                              "active-value": "1",
                              "inactive-value": "0",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handlerSwitch1(list)
                              },
                            },
                            model: {
                              value: list.value,
                              callback: function ($$v) {
                                _vm.$set(list, "value", $$v)
                              },
                              expression: "list.value",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  list.key == "total_money_type"
                    ? _c(
                        "div",
                        [
                          _c("el-switch", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              "active-text": list.text,
                              "active-value": "1",
                              "inactive-value": "0",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handlerSwitch1(list)
                              },
                            },
                            model: {
                              value: list.value,
                              callback: function ($$v) {
                                _vm.$set(list, "value", $$v)
                              },
                              expression: "list.value",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  list.key == "money_sign"
                    ? _c(
                        "div",
                        [
                          _c("el-input", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              placeholder: list.desc,
                              maxlength: 3,
                              "show-word-limit": "",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.handlerblur2(list.value, list.key)
                              },
                            },
                            model: {
                              value: list.value,
                              callback: function ($$v) {
                                _vm.$set(list, "value", $$v)
                              },
                              expression: "list.value",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              }),
            ],
            2
          ),
          _vm._l(_vm.print_dzx, function (item, index) {
            return _c("div", { key: index }, [
              item.key == "print_time_type"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("span", [_vm._v(_vm._s(item.text))]),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          on: {
                            change: function ($event) {
                              return _vm.changeTime2($event, item.key)
                            },
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        },
                        [_vm._v(" 服务器时间 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          on: {
                            change: function ($event) {
                              return _vm.changeTime2($event, item.key)
                            },
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        },
                        [_vm._v(" 当地手机时间 ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "135px",
                            "margin-top": "15px",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.desc) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "print_allot_type"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("span", [_vm._v(_vm._s(item.text))]),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          on: {
                            change: function ($event) {
                              return _vm.changeTime2($event, item.key)
                            },
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        },
                        [_vm._v(" 按商品添加顺序 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          on: {
                            change: function ($event) {
                              return _vm.changeTime2($event, item.key)
                            },
                          },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        },
                        [_vm._v(" 按商品及品牌权重 ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "135px",
                            "margin-top": "15px",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.desc) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              item.key == "print_sum_type"
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("span", [_vm._v(_vm._s(item.text))]),
                      _vm._l(item.option, function (itenoption, index) {
                        return _c(
                          "span",
                          { key: index },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: String(itenoption.id) },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeTime2($event, item.key)
                                  },
                                },
                                model: {
                                  value: item.value,
                                  callback: function ($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value",
                                },
                              },
                              [_vm._v(" " + _vm._s(itenoption.name) + " ")]
                            ),
                            index == 1
                              ? _c("el-input", {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    disabled: item.value != "1",
                                    placeholder: item.desc,
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.handlerblur2(
                                        item.value,
                                        item.key
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.option_value,
                                    callback: function ($$v) {
                                      _vm.$set(item, "option_value", $$v)
                                    },
                                    expression: "item.option_value",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
      _c("set-component", {
        ref: "setComponent",
        on: { "set-userid": _vm.setUserId, getlist: _vm.getlist },
      }),
      _c("set-component", {
        ref: "setComponentA",
        attrs: { chenlie: "" },
        on: { "set-userid": _vm.setUserIdA, getlist: _vm.getlist },
      }),
      _c("set-component", {
        ref: "setComponentPrintStaff",
        attrs: { chenlie: "" },
        on: { "set-userid": _vm.setUserIdPrintStaff, getlist: _vm.getlist },
      }),
      _c("set-component", {
        ref: "setComponenDefa",
        attrs: { opationkey: _vm.opationkey, chenlie: "" },
        on: { "set-userid": _vm.setUserIdDefa },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }