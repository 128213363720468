<!--
 * @Author: your name
 * @Date: 2021-01-26 15:11:08
 * @LastEditTime: 2021-05-13 16:51:01
 * @LastEditors: Please set LastEditors
 * @Description: 经营设置
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\operating\index.vue
-->
<template>
  <!-- NAME[epic=设置] 经营设置 -->
  <div style="padding: 20px">
    <h3>自动审核</h3>
    <el-row style="margin-top: 20px">
      <el-table stripe :data="tableData" :show-header="false">
        <el-table-column
          type="index"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template v-if="list.label == '开关'" #default="{ row }">
            <el-switch
              v-model="row.value"
              :active-value="'1'"
              :inactive-value="'0'"
              @change="handlerSwitch(row)"
            ></el-switch>
          </template>
          <template v-else-if="list.label == '功能审核'" #default="{ row }">
            {{ row.text }}
            <div v-if="row.key == 'auto_order_back'">
              <span>退库仓库:</span>
              <el-select
                v-model="depot"
                clearable
                size="mini"
                style="width: 120px; margin-left: 5px"
                placeholder="请选择退库仓库"
                @change="depotChange"
              >
                <el-option
                  v-for="(i, idx) in depotSelect"
                  :key="idx"
                  :value="i.id"
                  :label="i.depot_name"
                ></el-option>
              </el-select>
            </div>
            <div v-if="row.key == 'auto_order_car_back'">
              <span>退库仓库:</span>
              <el-select
                v-model="depot2"
                clearable
                size="mini"
                style="width: 120px; margin-left: 5px"
                placeholder="请选择退库仓库"
                @change="depotChange2"
              >
                <el-option
                  v-for="(i, idx) in depotSelect"
                  :key="idx"
                  :value="i.id"
                  :label="i.depot_name"
                ></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <h3>预订单审核控制</h3>
    <el-row style="margin-top: 20px">
      <el-table stripe :data="tableData3" :show-header="false">
        <el-table-column
          type="index"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template v-if="list.label == '开关'" #default="{ row }">
            <el-switch
              v-model="row.value"
              :active-value="'1'"
              :inactive-value="'0'"
              @change="handlerSwitch(row)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <h3>业务资源共享</h3>
    <el-row style="margin-top: 20px">
      <el-table stripe :data="tableData1" :show-header="false">
        <el-table-column
          type="index"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template v-if="list.label == '开关'" #default="{ row }">
            <el-switch
              v-model="row.value"
              :active-value="'1'"
              :inactive-value="'0'"
              @change="handlerSwitch(row)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <h3>业务规范</h3>
    <el-row style="margin-top: 20px">
      <el-table
        stripe
        :data="tableData2"
        :show-header="false"
        :span-method="arraySpanMethod"
      >
        <el-table-column
          type="index"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
        >
          <template v-if="list.label == '开关'" #default="{ row }">
            <el-switch
              v-if="index == 8"
              v-model="row.value"
              :active-value="'1'"
              :inactive-value="'0'"
              @change="handlerSwitch(row)"
            ></el-switch>
            <el-switch
              v-else
              v-model="row.value"
              :active-value="'1'"
              :inactive-value="'0'"
              @change="handlerSwitch(row)"
            ></el-switch>
          </template>
          <template v-else-if="list.label == '功能审核'" #default="{ row }">
            <div v-if="row.key == 'flag_date_type'" style="text-align: center">
              生产日期:
            </div>

            <div
              v-else-if="row.key == 'flag_visit_type'"
              style="text-align: center"
            >
              店铺拜访时间更新方式:
            </div>
            <!-- sss -->

            <div
              v-else-if="row.key == 'flag_depot_back'"
              style="text-align: center"
            >
              限定车存退库仓库
              <!-- //多选 -->
              <el-select
                v-model="depot3"
                multiple
                clearable
                size="mini"
                style="width: 120px; margin-left: 5px"
                placeholder="请选择限定车存退库仓库"
                @change="depotChangeO($event, 'depot_back')"
              >
                <el-option
                  v-for="(i, idx) in depotSelect"
                  :key="idx"
                  :value="i.id"
                  :label="i.depot_name"
                ></el-option>
              </el-select>
              <el-select
                v-model="depot4"
                clearable
                size="mini"
                style="width: 120px; margin-left: 5px"
                placeholder="请选择默认退库仓库"
                @change="depotChangeO($event, 'depot_back_default')"
              >
                <el-option
                  v-for="(i, idx) in depotSelectDefault"
                  :key="idx"
                  :value="i.id"
                  :label="i.depot_name"
                ></el-option>
              </el-select>
            </div>
            <!-- 16条 -->
            <div v-else-if="row.key == 'flag_carport_apply'">
              {{ row.text }}
              <span>限定人{{ row.option_value }}</span>
              <el-button
                type="primary"
                style="margin-left: 15px"
                @click="handleSetDefa(row)"
              >
                设置
              </el-button>
              <div>
                限定时间:
                <el-time-picker
                  v-model="time16"
                  style="width: 330px"
                  is-range
                  format="HH:mm"
                  value-format="HH:mm"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  @change="
                    ($event) => {
                      let a = $event
                      depotChangeO(a[0], 'carport_apply_start')
                      depotChangeO(a[1], 'carport_apply_end')
                    }
                  "
                ></el-time-picker>
              </div>
            </div>
            <div v-else-if="row.key == 'flag_order_follow'">
              {{ row.text }}
              <span>限定人{{ row.option_value }}</span>
              <el-button
                type="primary"
                style="margin-left: 15px"
                @click="handleSetDefa(row)"
              >
                设置
              </el-button>
            </div>
            <div v-else-if="row.key == 'flag_base_apply'">
              {{ row.text }}
              <el-time-picker
                v-model="time18"
                style="width: 330px"
                is-range
                format="HH:mm"
                value-format="HH:mm"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                @change="
                  ($event) => {
                    let a = $event
                    depotChangeO(a[0], row.option_key1)
                    depotChangeO(a[1], row.option_key2)
                  }
                "
              ></el-time-picker>
            </div>
            <div v-else-if="row.key == 'flag_order_apply'">
              {{ row.text }}
              <el-time-picker
                v-model="time19"
                style="width: 330px"
                is-range
                format="HH:mm"
                value-format="HH:mm"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                @change="
                  ($event) => {
                    let a = $event
                    depotChangeO(a[0], row.option_key1)
                    depotChangeO(a[1], row.option_key2)
                  }
                "
              ></el-time-picker>
            </div>
            <div v-else>{{ row.text }}</div>
          </template>
          <template v-else-if="list.label == '审核说明'" #default="{ row }">
            <div v-if="row.key == 'flag_date_type'">
              <el-radio v-model="row.value" label="2" @change="changeTime">
                系统智能推算成产日期
              </el-radio>
              <el-radio v-model="row.value" label="1" @change="changeTime">
                按最近三次采购先进先出
              </el-radio>
              <el-radio v-model="row.value" label="3" @change="changeTime">
                按最近三次采购后进先出
              </el-radio>
            </div>
            <div
              v-else-if="row.key == 'flag_visit_type'"
              style="text-align: center"
            >
              <el-radio
                v-model="row.value"
                label="1"
                @change="changeTime2($event, 'flag_visit_type')"
              >
                按业务员各自的拜访时间
              </el-radio>
              <el-radio
                v-model="row.value"
                label="2"
                @change="changeTime2($event, 'flag_visit_type')"
              >
                按店铺最新一次拜访时间（不区分拜访业代）
              </el-radio>
            </div>
            <div v-else>{{ row.desc }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <h3>其他设置</h3>
    <el-row style="margin-top: 20px">
      <div v-for="(item, idx) in other" :key="idx">
        <div v-if="item.key == 'pur_price_door'" style="margin-bottom: 20px">
          <!-- 按采购入库单动态计算商品加权平均成本价 -->
          <span>商品成本价核算方式：</span>
          <el-radio
            v-model="item.value"
            label="1"
            @change="changeTime2($event, 'pur_price_door')"
          >
            加权平均成本价（按采购入库单动态计算）
          </el-radio>
          <el-radio
            v-model="item.value"
            label="0"
            @change="changeTime2($event, 'pur_price_door')"
          >
            固定成本价（商品资料中设定的成本价）
          </el-radio>
          <!-- <el-switch
            v-model="item.value"
            :active-text="item.text"
            :active-value="'1'"
            :inactive-value="'0'"
            @change="handlerSwitch1(item)"
          ></el-switch> -->
          <div style="margin-left: 135px; margin-top: 15px">
            切换核算方式不影响之前已生成的销售单中的商品成本
          </div>
        </div>
        <div
          v-if="item.key == 'pur_price_user'"
          style="
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
          "
        >
          <div>
            <span>{{ item.desc }}:</span>
            <span style="margin-left: 15px">{{ item.text }}</span>
          </div>
          <el-button
            type="primary"
            style="margin-left: 15px"
            @click="handleSetA(item)"
          >
            设置
          </el-button>
        </div>
        <!-- <div v-if="item.key == 'distance_abnormal'" style="margin-bottom: 20px">
          <el-switch
            v-model="item.value"
            :active-text="item.text"
            :active-value="'1'"
            :inactive-value="'2'"
            @change="handlerSwitch1(item)"
          ></el-switch>
          <span style="margin-left: 10px">{{ item.desc }}</span>
        </div> -->
        <div v-if="item.key == 'receive_only'" style="margin-bottom: 20px">
          <el-switch
            v-model="item.value"
            :active-text="item.text"
            :active-value="'1'"
            :inactive-value="'0'"
            @change="handlerSwitch1(item)"
          ></el-switch>
          <span style="margin-left: 10px">{{ item.desc }}</span>
        </div>
        <div v-if="item.key == 'distribute_only'" style="margin-bottom: 20px">
          <el-switch
            v-model="item.value"
            :active-text="item.text"
            :active-value="'1'"
            :inactive-value="'0'"
            @change="handlerSwitch1(item)"
          ></el-switch>
          <span style="margin-left: 10px">{{ item.desc }}</span>
        </div>

        <div v-if="item.key == 'staff_order_print'" style="margin-bottom: 20px">
          <el-switch
            v-model="item.value"
            :active-text="item.text"
            :active-value="'1'"
            :inactive-value="'0'"
            @change="handlerSwitch1(item)"
          ></el-switch>
          <span style="margin-left: 10px">{{ item.desc }}</span>
        </div>
        <div v-if="item.key == 'staff_base_print'" style="margin-bottom: 20px">
          <el-switch
            v-model="item.value"
            :active-text="item.text"
            :active-value="'1'"
            :inactive-value="'0'"
            @change="handlerSwitch1(item)"
          ></el-switch>
          <span style="margin-left: 10px">{{ item.desc }}</span>
        </div>
        <!-- 采购订单可审核的员工 设置 -->
        <div
          v-if="item.key == 'pur_check_user'"
          style="
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
          "
        >
          <div>
            <span>{{ item.desc }}:</span>
            <span style="margin-left: 15px">{{ item.text }}</span>
          </div>
          <el-button
            type="primary"
            style="margin-left: 15px"
            @click="handleSetA(item)"
          >
            设置
          </el-button>
        </div>
        <div
          v-if="item.key == 'clear_user'"
          style="
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
          "
        >
          <div>
            <span>{{ item.desc }}:</span>
            <span style="margin-left: 15px">{{ item.text }}</span>
          </div>
          <el-button
            type="primary"
            style="margin-left: 15px"
            @click="handleSetA(item)"
          >
            设置
          </el-button>
        </div>

        <!-- 自动打印员工 -->
        <div
          v-if="item.key == 'print_auto_user'"
          style="
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
          "
        >
          <div>
            <span>{{ item.desc }}:</span>
            <span style="margin-left: 15px">{{ item.text }}</span>
          </div>
          <el-button
            type="primary"
            style="margin-left: 15px"
            @click="handleSetPringStaff(item)"
          >
            设置
          </el-button>
          <span>（为避免重复打印，仅可添加一名员工账号）</span>
        </div>

        <!-- 车存申请在线支付 -->
        <div v-if="item.key == 'allot_pay_door'" style="margin-bottom: 20px">
          <el-switch
            v-model="item.value"
            :active-text="item.text"
            :active-value="'1'"
            :inactive-value="'0'"
            @change="handlerSwitch1(item)"
          ></el-switch>
          <span style="margin-left: 10px">{{ item.desc }}</span>
        </div>

        <div
          v-if="item.key == 'allot_pay_user'"
          style="
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
          "
        >
          <div>
            <span>{{ item.desc }}:</span>
            <span style="margin-left: 15px">{{ item.text }}</span>
          </div>
          <el-button
            type="primary"
            style="margin-left: 15px"
            @click="handleSetA(item)"
          >
            设置
          </el-button>
        </div>

        <!-- 预订单（访销/网销订单）不用调拨 -->
        <div v-if="item.key == 'pre_process_door'" style="margin-bottom: 20px">
          <el-switch
            v-model="item.value"
            :active-text="item.text"
            :active-value="'1'"
            :inactive-value="'0'"
            @change="handlerSwitch1(item)"
          ></el-switch>
          <div style="margin-top: 10px">{{ item.desc }}</div>
        </div>
      </div>

      <div style="display: flex; flex-direction: row; align-items: center">
        <span>拍照水印显示：</span>
        <!-- <el-checkbox-group v-model="Takepictures">
          <el-checkbox v-for="list in takep" :key="list.id" :label="list.id">
            {{ list.name }}
          </el-checkbox>
        </el-checkbox-group> -->
        <el-switch
          v-for="(list, index) in takep"
          :key="index"
          v-model="list.value"
          :active-text="list.text"
          :active-value="'1'"
          :inactive-value="'0'"
          @change="handlerSwitch1(list)"
        ></el-switch>
      </div>
      <br />
      <br />
      <span>允许看成本价员工：</span>
      <span style="margin: 0px 15px">{{ user_data }}</span>
      <el-button type="primary" @click="handleSet">设置</el-button>
      <br />
      <br />
      <span>距离门店超过</span>
      <el-input
        v-model="juli"
        placeholder="请输入范围半径"
        style="width: 120px"
        onkeyup="value=value.replace(/[^\d.]/g,'') "
        @blur="handlerblur"
      />
      <span>千米，做拜访操作，判断为位置异常 默认为0.5KM</span>
    </el-row>
    <div style="margin-top: 15px">
      <span style="margin-right: 10px">奖品成本计入毛利:</span>
      <el-switch
        v-for="(list, index) in chenlielist"
        :key="index"
        v-model="list.value"
        :active-text="list.text"
        :active-value="'1'"
        :inactive-value="'0'"
        style="margin-right: 10px"
        @change="handlerSwitch1(list)"
      ></el-switch>
      <div style="margin-top: 10px">
        默认开启，关闭后，该销售类型的商品毛利为0
      </div>
      <div style="margin-top: 15px; display: flex; align-items: center">
        <span style="margin-right: 10px">手机端打印:</span>
        <div v-for="(list, index) in other" :key="index">
          <div v-if="list.key == 'single_money_type'">
            <!-- 单价货币符号 -->
            <el-switch
              v-model="list.value"
              :active-text="list.text"
              :active-value="'1'"
              :inactive-value="'0'"
              style="margin-right: 10px"
              @change="handlerSwitch1(list)"
            ></el-switch>
          </div>
          <div v-if="list.key == 'total_money_type'">
            <el-switch
              v-model="list.value"
              :active-text="list.text"
              :active-value="'1'"
              :inactive-value="'0'"
              style="margin-right: 10px"
              @change="handlerSwitch1(list)"
            ></el-switch>
          </div>
          <div v-if="list.key == 'money_sign'">
            <!-- 货币符号 -->
            <el-input
              v-model="list.value"
              style="width: 120px"
              :placeholder="list.desc"
              :maxlength="3"
              show-word-limit
              @blur="handlerblur2(list.value, list.key)"
            ></el-input>
          </div>
        </div>
      </div>
      <div v-for="(item, index) in print_dzx" :key="index">
        <div v-if="item.key == 'print_time_type'" style="margin-bottom: 20px">
          <!-- 按采购入库单动态计算商品加权平均成本价 -->
          <span>{{ item.text }}</span>
          <el-radio
            v-model="item.value"
            label="1"
            @change="changeTime2($event, item.key)"
          >
            服务器时间
          </el-radio>
          <el-radio
            v-model="item.value"
            label="0"
            @change="changeTime2($event, item.key)"
          >
            当地手机时间
          </el-radio>

          <div style="margin-left: 135px; margin-top: 15px">
            {{ item.desc }}
          </div>
        </div>
        <div v-if="item.key == 'print_allot_type'" style="margin-bottom: 20px">
          <!-- 按采购入库单动态计算商品加权平均成本价 -->
          <span>{{ item.text }}</span>
          <el-radio
            v-model="item.value"
            label="1"
            @change="changeTime2($event, item.key)"
          >
            按商品添加顺序
          </el-radio>
          <el-radio
            v-model="item.value"
            label="0"
            @change="changeTime2($event, item.key)"
          >
            按商品及品牌权重
          </el-radio>

          <div style="margin-left: 135px; margin-top: 15px">
            {{ item.desc }}
          </div>
        </div>
        <div v-if="item.key == 'print_sum_type'" style="margin-bottom: 20px">
          <!-- 按采购入库单动态计算商品加权平均成本价 -->
          <span>{{ item.text }}</span>
          <span v-for="(itenoption, index) in item.option" :key="index">
            <el-radio
              v-model="item.value"
              :label="String(itenoption.id)"
              @change="changeTime2($event, item.key)"
            >
              {{ itenoption.name }}
            </el-radio>
            <el-input
              v-if="index == 1"
              v-model="item.option_value"
              :disabled="item.value != '1'"
              style="width: 120px"
              :placeholder="item.desc"
              @blur="handlerblur2(item.value, item.key)"
            ></el-input>
          </span>
        </div>
      </div>
    </div>

    <set-component
      ref="setComponent"
      @set-userid="setUserId"
      @getlist="getlist"
    ></set-component>
    <set-component
      ref="setComponentA"
      chenlie
      @set-userid="setUserIdA"
      @getlist="getlist"
    ></set-component>

    <set-component
      ref="setComponentPrintStaff"
      chenlie
      @set-userid="setUserIdPrintStaff"
      @getlist="getlist"
    ></set-component>
    <!-- 16条用 -->
    <set-component
      ref="setComponenDefa"
      :opationkey="opationkey"
      chenlie
      @set-userid="setUserIdDefa"
    ></set-component>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import { getAllSelect2 } from '@/api/advanceOrder'
  import SetComponent from '@/views/project/sale/control/setPrice/components/createEdit/setComponent'
  export default {
    components: {
      SetComponent,
    },
    data() {
      return {
        opationkey: '',
        juli: '',
        other: [],
        Takepictures: [],
        takep: [],
        tableData: [],
        tableData1: [],
        tableData2: [],
        tableData3: [],
        chenlielist: [],
        fuhao: '',
        colemd: [
          {
            label: '开关',
            align: 'center',
            prop: 'name',
            width: '150',
          },
          {
            label: '功能审核',
            align: '',
            prop: 'text',
            width: '320',
          },
          {
            label: '审核说明',
            align: '',
            prop: 'desc',
            width: '400',
          },
        ],
        url: {
          list: '/setAdmin/config/index',
          Switch: '/setAdmin/config/set',
          user: '/setAdmin/config/user-set',
        },
        user_data: 0,
        rel_user: [],
        depot: '',
        depot2: '',
        depot3: [], //限定车存退货下拉
        depot4: '', //限定车存退货下拉默认值
        time16: [],
        time18: [],
        time19: [],
        depotSelect: [],
        // 当前设置的人的key
        typeS: '',
        print_dzx: [],
      }
    },
    computed: {
      depotSelectDefault() {
        let arr = []
        try {
          this.depot3.forEach((i) => {
            let a = this.depotSelect.filter((z) => {
              return z.id == i
            })
            if (a[0]) {
              arr.push(a[0])
            }
          })
        } catch (err) {
          console.log('depotSelectDefault err', err)
        }
        console.log('arr', arr)
        return arr || []
      },
    },
    watch: {
      // time16(val) {
      //   console.log(val)
      // },
    },
    mounted() {
      // 仓库下拉
      getAllSelect2().then((res) => {
        console.log(res)
        this.depotSelect = res.data
      })
      this.handlerlist()
    },
    methods: {
      handlerblur() {
        postAction('/setAdmin/config/set', {
          cfg_name: 'distance_abnormal',
          cfg_value: this.juli,
        })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {})
      },
      // 货币符号用
      handlerblur2(value, key) {
        postAction('/setAdmin/config/set', {
          cfg_name: key,
          cfg_value: value,
        })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {})
      },
      handlerSwitch1(list) {
        console.log(list)
        if (list.key == 'photo_addr') {
          if (list.value == '0') {
            list.value = '2'
          }
        }
        postAction('/setAdmin/config/set', {
          cfg_name: list.key,
          cfg_value: list.value,
        })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {
            this.handlerlist()
          })
      },
      handleSet() {
        this.$refs.setComponent.showDialog = true
        this.$refs.setComponent.saveshow = 2
        this.$refs.setComponent.handlerdatalist()
      },
      getlist() {
        this.handlerlist()
      },
      setUserId(val) {
        console.log(val, val.split(','), '经营设置查看成本价')
        this.user_data = val.split(',').length
        postAction(this.url.user, { user: JSON.stringify(val.split(',')) })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {})
        // this.data.limit_count = this.data.limit_users.split(',').length
      },
      handlerlist() {
        postAction(this.url.list, {})
          .then((res) => {
            console.log(res, '所有的东西')
            this.tableData2 = res.data.flag
            this.tableData = res.data.auto
            this.tableData1 = res.data.share
            this.tableData3 = res.data.pre
            this.print_dzx = res.data.print_dzx
            this.takep = res.data.photo
            this.depot = res.data.order_back_depot
            this.depot2 = res.data.order_car_back_depot
            console.log(res.data.other, '开关量')
            this.other = res.data.other

            this.time18 = [
              res.data.flag[17].option_value1,
              res.data.flag[17].option_value2,
            ]
            this.time19 = [
              res.data.flag[18].option_value1,
              res.data.flag[18].option_value2,
            ]

            // 第15条 赋值
            // console.log(Object.keys(), '第15条 赋值')
            // Object.keys()
            res.data.flag.forEach((i) => {
              if (i.key == 'flag_depot_back') {
                if (i.option_value !== '') {
                  this.depot3 = i.option_value.split(',')
                }
              }
            })
            // if (res.data.flag[14].option_value !== '') {
            //   this.depot3 = res.data.flag[14].option_value.split(',')
            // }

            this.depot4 = res.data.flag[14].option_value2

            this.time16 = [
              res.data.flag[16].option_value1,
              res.data.flag[16].option_value2,
            ]

            this.depotbk = res.data.order_back_depot
            this.chenlielist = res.data.promote_rate
            let juli = res.data.other.filter(
              (i) => i.key == 'distance_abnormal'
            )[0]
            this.juli = juli && juli.value

            this.rel_user = res.data.rel_user
            this.user_data = this.rel_user.length
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerSwitch(row) {
        var status = 0
        if (row.value != 0) {
          status = 1
          postAction(this.url.Switch, { cfg_name: row.key, cfg_value: status })
            .then((res) => {
              console.log(res)
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.handlerlist()
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          postAction(this.url.Switch, { cfg_name: row.key, cfg_value: status })
            .then((res) => {
              console.log(res)
              this.$message({
                message: res.msg,
                type: 'success',
              })
              this.handlerlist()
            })
            .catch((err) => {
              console.log(err)
            })
        }
      },
      depotChange(v) {
        console.log('', v)
        postAction('/setAdmin/config/set', {
          cfg_name: 'order_back_depot',
          cfg_value: v,
        })
          .then((r) => {
            this.$message.success('修改成功')
            this.handlerlist()
          })
          .catch(() => {
            this.depot = this.depotbk
          })
      },
      depotChangeO(v, key) {
        console.log('', v)
        if (v instanceof Array) {
          v = v.join()
        }
        postAction('/setAdmin/config/set', {
          cfg_name: key,
          cfg_value: v,
        })
          .then((r) => {
            this.$message.success('修改成功')
            this.handlerlist()
          })
          .catch(() => {
            this.depot = this.depotbk
          })
      },
      depotChange2(v) {
        postAction('/setAdmin/config/set', {
          cfg_name: 'order_car_back_depot',
          cfg_value: v,
        })
          .then((r) => {
            this.$message.success('修改成功')
            this.handlerlist()
          })
          .catch(() => {
            this.depot = this.depotbk
          })
      },
      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        console.log(row, column, rowIndex, columnIndex)
        // rowIndex == 13 || rowIndex == 14
        if (row.key == 'flag_date_type' || row.key == 'flag_visit_type') {
          if (columnIndex === 3) {
            return [1, 2]
          } else if (columnIndex === 1) {
            return [0, 0]
          }
        }
      },
      changeTime2(v, key) {
        postAction('/setAdmin/config/set', {
          cfg_name: key,
          cfg_value: v,
        })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {})
      },
      changeTime(v) {
        console.log('v', v)
        postAction('/setAdmin/config/set', {
          cfg_name: 'flag_date_type',
          cfg_value: v,
        })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerlist()
          })
          .catch((err) => {})
      },
      // 第16条限定人员用
      handleSetDefa(row) {
        console.log('rr', row)
        this.typeS = row.option_key
        this.opationkey = row.option_key
        console.log('typeS', this.typeS)
        setTimeout(() => {
          this.$refs.setComponenDefa.getUserList(row.list)
        }, 500)
        this.$refs.setComponenDefa.showDialog = true
        this.$refs.setComponenDefa.saveshow = 3
      },
      setUserIdDefa(data, key) {
        console.log('data', data, key)
        this.depotChangeO(data, key)
      },
      handleSetA(row) {
        this.typeS = row.key
        console.log('typeS', this.typeS)
        setTimeout(() => {
          this.$refs.setComponentA.getUserList(row.value.split())
        }, 500)
        this.$refs.setComponentA.showDialog = true
        this.$refs.setComponentA.saveshow = 3
      },
      handleSetPringStaff(row) {
        this.typeS = row.key
        console.log('typeS', this.typeS)
        setTimeout(() => {
          this.$refs.setComponentPrintStaff.getUserList(row.value.split())
        }, 500)
        this.$refs.setComponentPrintStaff.showDialog = true
        this.$refs.setComponentPrintStaff.saveshow = 3
      },
      setUserIdPrintStaff(list) {
        console.log(list)
        const s = this.typeS
        postAction('/setAdmin/config/set', {
          cfg_name: s,
          cfg_value: list.join(','),
        }).then((r) => {
          this.$message.success('保存成功')
          this.handlerlist()
        })
      },
      setUserIdA(list) {
        console.log(list)
        const s = this.typeS
        if (s == 'clear_user') {
          postAction('/setAdmin/config/set', {
            cfg_name: s,
            cfg_value: list.join(','),
            user_type: 3,
          }).then((r) => {
            this.$message.success('保存成功')
            this.handlerlist()
          })
        } else {
          postAction('/setAdmin/config/set', {
            cfg_name: s,
            cfg_value: list.join(','),
          }).then((r) => {
            this.$message.success('保存成功')
            this.handlerlist()
          })
        }
      },
    },
  }
</script>

<style></style>
